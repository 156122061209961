<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 gap-6">
      <MopfButtonsGroup :buttons="buttons" />
    </div>

    <div
      :key="surveyResponseOrder"
      class="xl:relative rounded-md overflow-hidden shadow-lg h-auto bg-white border-gray-100 py-10"
    >
      <div class="flex justify-between">
        <div class="pl-18">
          <span class="text-4xl font-medium">{{ practiceName }}</span>
        </div>
        <div class="flex space-x-4 justify-end pr-10 w-1/4">
          <v-select
            class="w-full"
            label="label"
            placeholder="Show By"
            :searchable="false"
            :options="shoyByOptions"
            v-model="surveyResponseOrder"
          >
          </v-select>
        </div>
      </div>
      <span class="text-2xl tracking-tighter font-medium text-gray-500 pl-18"
        >Survey Responses {{ practiceSurveyCount }}</span
      >
      <div
        class="border-b-2 border-[#ECECEC] drop-shadow-2xl w-full pb-10"
      ></div>
      <SurveyResponseChart
        :key="optionStats"
        :order="surveyResponseOrder?.value"
        :data="optionStats"
        :loader="loadingQuestionTable"
        class="h-full"
      />
    </div>
    <div class="border-2 border-gray-100 rounded-md bg-white">
      <SurveyQuestionsTable
        v-if="questionStats?.length > 0"
        :tableHeadings="surveyTableHeadings"
        :tableData="questionStats"
        :detailPage="true"
        :actionPlanPageRoute="actionPlanPageRoute"
        :loader="loadingQuestionTable"
      />
    </div>
    <div class="border-2 border-gray-100 rounded-md bg-white">
      <div class="flex flex-row items-center py-1 lg:px-0 sm:px-6 px-6">
        <div class="justify-items-start p-4 text-lg w-full">
          <p>{{ practiceName }} Action Plans</p>
        </div>
        <div class="flex space-x-4 justify-end w-11/12 p-4 z-10">
          <div
            class="border border-gray-300 bg-white pt-1 rounded leading-tight w-full"
          >
            <DatePicker
              :selectedDate="actionPlanFrameDate"
              @selected-date="actionPlanMeetingFilter"
              @cleared-date="actionPlanMeetingFilter"
              placeholder="Meeting Date"
            />
          </div>
          <div
            class="border border-gray-300 bg-white pt-1 rounded leading-tight w-full"
          >
            <DatePicker
              :selectedDate="actionPlanCreationDate"
              @selected-date="actionPlanCreationFilter"
              @cleared-date="actionPlanCreationFilter"
              placeholder="Creation Date"
            />
          </div>
          <v-select
            class="w-full"
            label="label"
            placeholder="Status"
            :searchable="false"
            :options="statusList"
            v-model="actionPlanStatusValue"
            @update:modelValue="actionPlanStatus"
          >
          </v-select>
        </div>
      </div>
      <ActionPlanTable
        :key="actionPlansTableData?.data"
        :tableData="actionPlansTableData?.data"
        :tableHeadings="tableHeadings"
        :loader="loadingActionPlanTable"
        :routeName="actionPlanDetailsRoute"
      />
      <Pagination
        v-if="actionPlansTableData?.data?.length > 0"
        :currentPage="actionPlansTableData?.current_page"
        :totalPages="actionPlansTableData?.last_page"
        @page-changed="fetchActionPlanPage"
      />
    </div>
    <div class="border-2 border-gray-100 rounded-md bg-white">
      <div>
        <div class="flex space-x-4 justify-end p-6 z-10">
          <div class="text-lg w-full">
            <p>Feedbacks</p>
          </div>
          <v-select
            class="w-1/3"
            label="label"
            placeholder="Feedback Type"
            :searchable="false"
            :options="feedbackTypeList"
            @update:modelValue="filterFeedbackByType"
          >
          </v-select>
          <div
            class="border border-gray-300 bg-white pt-1 rounded leading-tight w-1/3"
          >
            <DatePicker
              @selected-date="filterFeedbacksByDate"
              :selectedDate="selectedDate"
              @cleared-date="filterFeedbacksByDate"
              placeholder="Date"
            />
          </div>
        </div>
      </div>
      <FeedbacksTable
        :tableHeadings="feedbacksTableHeadings"
        :tableData="feedbacks"
        :loader="loadingFeedbackTable"
      />
      <Pagination
        :currentPage="feedbacks?.current_page"
        :totalPages="feedbacks?.last_page"
        @page-changed="fetchFeedbacksPage"
      />
    </div>
    <div class="py-4">
      <SurveyList />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

import Pagination from "@/components/newUi/atoms/Pagination.vue";

import MopfButtonsGroup from "@/components/newUi/baseComponents/MopfButtonsGroup.vue";
import SurveyResponseChart from "@/components/newUi/charts/SurveyResponseChart.vue";
import FeedbacksTable from "@/components/newUi/tables/FeedbacksTable.vue";
import ActionPlanTable from "@/components/newUi/tables/ActionPlanTable.vue";
import SurveyQuestionsTable from "@/components/newUi/tables/SurveyQuestionsTable.vue";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";
import SurveyList from "@/components/newUi/PatientSurveys/SurveyList.vue";

const store = useStore();
const selectedDate = ref(null);
const feedbackType = ref(null);
const loadingActionPlanTable = ref(true);
const loadingFeedbackTable = ref(true);
const loadingQuestionTable = ref(true);
const statusList = ref([
  { label: "In-Progress", value: "in-progress" },
  { label: "Completed", value: "completed" },
]);
const tableHeadings = ref([
  "Agenda",
  "Creation Date",
  "Meeting Count",
  "Meeting Date",
  "Agenda Status",
  "Time Frame",
  "",
]);
const practiceId = localStorage.getItem("practice");
const surveyTableHeadings = ref(["Name", "Percentage", "Average", ""]);
const feedbackTypeList = ref(["Positive", "Negative"]);
const feedbacksTableHeadings = ref([
  "Feedbacks",
  "Date",
  "Practice",
  "Type of Feedback",
]);
const buttons = ref([
  {
    component: "ma-mopfAddPhysicalSurvey",
    text: "Add Physical Survey",
    icon: "tab",
    bgColor: "bg-teal",
    textColor: "text-teal",
  },
  {
    component: "ma-mopfCreateActionPlan-ps",
    text: "Create Action Plan",
    params: {
      qof: false,
    },
    icon: "backup_table",
    bgColor: "bg-aquamarine",
    textColor: "text-aquamarine",
  },
]);
const actionPlanDetailsRoute = ref("ma-actionPlan-detail-ps");
const actionPlanPageRoute = ref("ma-mopfQuestionActionPlan-ps");
const practiceName = localStorage.getItem("practice_name");
const shoyByOptions = ref([
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
]);
const surveyResponseOrder = ref(null);
const actionPlanFrameDate = ref(null);
const actionPlanCreationDate = ref(null);
const actionPlanPage = ref(1);
const actionPlanStatusValue = ref(null);

onMounted(async () => {
  await store.dispatch("MAPatientSurveys/fetchOptionStats").then(() => {
    loadingQuestionTable.value = false;
  });
  fetchAllActionPlans({ tag: "patient-survey", page: 1 });
  await store.dispatch("MAPatientSurveys/fetchAllSurveys");
});
loadingFeedbackTable.value = true;
store.dispatch("MAPatientSurveys/fetchPracticeSurveyCount");
store.dispatch("MAPatientSurveys/fetchQuestionStats", practiceId);
store.dispatch("MAPatientSurveys/fetchActiveSurvey");
store.dispatch("MAPatientSurveys/fetchFeedbacks", { page: 1 }).then(() => {
  loadingFeedbackTable.value = false;
});
const feedbacks = computed(() => {
  return store.getters["MAPatientSurveys/getFeedbacks"];
});

const fetchAllActionPlans = async (payload) => {
  loadingActionPlanTable.value = true;
  await store
    .dispatch("MAActionPlans/fetchAllActionPlans", payload)
    .then(() => {
      loadingActionPlanTable.value = false;
    });
};

const optionStats = computed(() => {
  return store.getters["MAPatientSurveys/getOptionStats"];
});

const questionStats = computed(() => {
  return store.getters["MAPatientSurveys/getQuestionStats"];
});

const actionPlansTableData = computed(() => {
  return store.getters["MAActionPlans/getActionPlans"];
});

const practiceSurveyCount = computed(() => {
  return store.getters["MAPatientSurveys/getPracticeSurveyCount"];
});

const actionPlanStatus = () => {
  applyActionPlanFilters();
};

const fetchActionPlanPage = (page) => {
  if (page > actionPlansTableData.value.last_page) {
    return;
  } else {
    fetchAllActionPlans({
      tag: "patient-survey",
      practice: practiceId,
      status: actionPlanStatusValue?.value?.value,
      page: page,
      creation_date: actionPlanCreationDate?.value,
      time_frame: actionPlanFrameDate?.value,
    });
  }
};
const filterFeedbacksByDate = (dateSelected) => {
  if (dateSelected) {
    selectedDate.value = dateSelected;
  } else {
    selectedDate.value = null;
  }
  applyFiltersAndFetch();
};

const filterFeedbackByType = (value) => {
  feedbackType.value = value;
  applyFiltersAndFetch();
};

const applyFiltersAndFetch = async (page = feedbacks.value?.current_page) => {
  try {
    const filters = {
      date: selectedDate.value,
      feedbackType: feedbackType?.value?.toLowerCase(),
      page: page,
    };
    loadingFeedbackTable.value = true;
    await store
      .dispatch("MAPatientSurveys/fetchFeedbacks", filters)
      .then(() => {
        loadingFeedbackTable.value = false;
      });
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const fetchFeedbacksPage = (page) => {
  if (page > feedbacks.value.last_page) {
    return;
  } else {
    applyFiltersAndFetch(page);
  }
};

const actionPlanMeetingFilter = (selectedDate) => {
  actionPlanFrameDate.value = selectedDate;
  applyActionPlanFilters();
};

const actionPlanCreationFilter = (dateSelected) => {
  if (dateSelected) {
    actionPlanCreationDate.value = dateSelected;
  } else {
    actionPlanCreationDate.value = dateSelected;
  }
  applyActionPlanFilters();
};

const applyActionPlanFilters = async () => {
  fetchAllActionPlans({
    tag: "patient-survey",
    practice: practiceId,
    status: actionPlanStatusValue?.value?.value,
    page: actionPlanPage?.value,
    creation_date: actionPlanCreationDate?.value,
    meeting_date: actionPlanFrameDate?.value,
  });
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
</style>
